var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c("section", [
          _c("h3", [
            _vm._v(_vm._s(_vm.timeSign) + "，" + _vm._s(_vm.realname) + "！")
          ]),
          _c("p", [_vm._v("欢迎使用由英海瑞特科技研发系统，祝您工作愉快~")])
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("section", [
          _c("h3", [_vm._v("车辆总数")]),
          _c("p", [_vm._v(_vm._s(_vm.revenue.vehicleTotal || 0))])
        ]),
        _c("section", [
          _c("h3", [_vm._v("在线车辆")]),
          _c("p", [_vm._v(_vm._s(_vm.revenue.vehicleOnline || 0))])
        ]),
        _c("section", [
          _c("h3", [_vm._v("载客次数")]),
          _c("p", [_vm._v(_vm._s(_vm.revenueR.operateTotal || 0))])
        ]),
        _c("section", [
          _c("h3", [_vm._v("营收（元）")]),
          _c("p", [_vm._v(_vm._s((_vm.revenueR.amountTotal / 100).toFixed(2)))])
        ])
      ])
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "item item1" },
        [_c("charts", { attrs: { option: _vm.item1Option, id: _vm.item1 } })],
        1
      ),
      _c("div", { staticClass: "item item2" }, [_c("heat-map")], 1),
      _c("div", { staticClass: "item item3" }, [
        _c("p", { staticClass: "title" }, [_vm._v("报警处理情况")]),
        _c("div", { staticClass: "data" }, [
          _c(
            "div",
            { staticClass: "pie" },
            [
              _c("charts", {
                attrs: { option: _vm.item3Option, id: _vm.item3 }
              })
            ],
            1
          ),
          _c("div", { staticClass: "information" }, [
            _c("p", { staticClass: "rate-title" }, [_vm._v("处理率")]),
            _c("p", { staticClass: "rate-num" }, [
              _c("span", [
                _vm._v(
                  _vm._s((_vm.item3Value.disposeRatio * 100 || 0).toFixed(2))
                )
              ]),
              _c("span", [_vm._v("%")])
            ]),
            _c("p", { staticClass: "solved" }, [
              _c("span", [_vm._v("已处理")]),
              _c("span", [_vm._v(_vm._s(_vm.item3Value.disposeCount || 0))])
            ]),
            _c("p", { staticClass: "unsolved" }, [
              _c("span", [_vm._v("未处理")]),
              _c("span", [_vm._v(_vm._s(_vm.item3Value.initCount || 0))])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "item item4" }, [
        _c("p", { staticClass: "title" }, [_vm._v("收入排行")]),
        _c(
          "div",
          { staticClass: "rank-table" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.item4Data, "show-header": false } },
              [
                _c("el-table-column", {
                  attrs: { label: "排行", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { class: "rank" + scope.row.order }, [
                            _vm._v(" " + _vm._s(scope.row.order) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "cph", label: "车牌号" }
                }),
                _c("el-table-column", {
                  attrs: { label: "收入", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("incomeFilter")(
                                  scope.row.operatingAmount
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "item item5" }, [
        _c("p", { staticClass: "title" }, [_vm._v("报警排行")]),
        _c(
          "div",
          { staticClass: "rank-table" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.item5Data, "show-header": false } },
              [
                _c("el-table-column", {
                  attrs: { label: "排行", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { class: "rank" + scope.row.order }, [
                            _vm._v(" " + _vm._s(scope.row.order) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "cph", label: "车牌号" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "vehicleGroupCount",
                    label: "次数",
                    width: "150"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.total))])]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "item item6" },
        [_c("charts", { attrs: { option: _vm.item6Option, id: _vm.item6 } })],
        1
      ),
      _c("div", { staticClass: "item item8" }, [
        _c("p", { staticClass: "title" }, [_vm._v("报警类型排行")]),
        _c(
          "div",
          { staticClass: "rank-table" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.item8Data, "show-header": false } },
              [
                _c("el-table-column", {
                  attrs: { label: "排行", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { class: "rank" + scope.row.order }, [
                            _vm._v(" " + _vm._s(scope.row.order) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "alarmName",
                    label: "报警类型",
                    "show-overflow-tooltip": true
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "total", label: "报警次数", width: "150" }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }