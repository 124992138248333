<template>
  <div class="map" id="container">
    <p class="title">乘客上车热力图</p>
    <!-- <el-select v-model="value" placeholder="请选择" class="select" @change="onChangeTime">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>-->
  </div>
</template>

<script>
import { queryIncomeByDistributionMap } from '@/api/lib/taxi-api.js'
export default {
  name: 'headMap',
  data () {
    return {
      map: null,
      value: 'today',
      options: [
        {
          value: 'today',
          label: '今天'
        },
        {
          value: 'yesterday',
          label: '昨天'
        },
        {
          value: 'lastweek',
          label: '上周'
        },
        {
          value: 'lastmonth',
          label: '上个月'
        },
        {
          value: 'thisyear',
          label: '今年'
        }
      ],
      heatmapData: []
    }
  },
  methods: {
    initMap () {
      let self = this
      // eslint-disable-next-line no-undef
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        resizeEnable: true,
         center:[105.829757, 32.433668],//this.$store.state.user.lnglat,
        zoom: 12
      })
      if (!self.isSupportCanvas()) {
        alert('热力图仅对支持canvas的浏览器适用,您所使用的浏览器不能使用热力图功能,请换个浏览器试试~')
      }
      this.map = map
    },
    isSupportCanvas () {
      // 判断浏览区是否支持canvas
      var elem = document.createElement('canvas')
      return !!(elem.getContext && elem.getContext('2d'))
    },
    onChangeTime (v) {
      console.log(v)
    }
  },
  created () {
    this.isSupportCanvas()
  },
  mounted () {
    this.initMap()
    let self = this
    self.map.on('complete', function () {
      /** *乘车热力分布图 */
      queryIncomeByDistributionMap({}).then(res => {
        self.heatmapData = [...res.data]
        let heatmap
        self.map.plugin(['AMap.Heatmap'], function () {
          // 初始化heatmap对象
          // eslint-disable-next-line no-undef
          heatmap = new AMap.Heatmap(self.map, {
            radius: 25, // 给定半径
            opacity: [0, 0.8]
            // gradient: {
            //   0.5: 'blue',
            //   0.65: 'rgb(117,211,248)',
            //   0.7: 'rgb(0, 255, 0)',
            //   0.9: '#ffea00',
            //   1.0: 'red'
            // }
          })
          // 设置数据集
          heatmap.setDataSet({
            data: self.heatmapData,
            max: 100
          })
        })
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
  width: 100%;
  position: relative;
  @include themify() {
    .title {
      position: absolute;
      top: 1.5vh;
      left: 1.5vh;
      font-size: (16rem/14);
      color: themed("n7");
      z-index: 999;
      margin: 0;
      user-select: none;
    }
    .select {
      position: absolute;
      top: 1.5vh;
      right: 1.5vh;
      z-index: 999;
      width: 7vw;
    }
  }
}
</style>
